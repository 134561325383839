

















































import {Component, Vue} from 'vue-property-decorator';
import Settings from "@/packages/settings/Settings";
import {Auth} from "@/packages/auth/Auth";

@Component
export default class EpisodeDetail extends Vue {
  public episode = {
    id: 0
  };
  public settings: Settings;
  public id: number;

  public constructor() {
    super();
    this.settings = new Settings();
    this.id = +this.$route.params.id;
  }

  public loadData(): void {
    this.axios.get(this.settings.settings.host + '/api/episodes/' + this.$route.params.id, { //this.id.toString(),{
      params: {},
      headers: {
        Authorization: 'Bearer ' + Auth.getToken()
      }
    }).then(response => {
      this.episode = response.data;
    });
  }

  public deleteQuestion(idx, questionId): void {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    if (this.episode.id > 0) {
      this.axios.delete(
          this.settings.settings.host + '/api/episode-questions/' + questionId,
          {
            headers: {
              Authorization: 'Bearer ' + Auth.getToken()
            }
          }
      ).then(data => {
        that.$notify({
          group: 'notify',
          type: 'success',
          text: 'Pytanie zostało usunięte'
        });
      })
    }
    this.episode.episodeQuestions.splice(idx, 1);
  }

  created(): void {
    this.loadData();
  }
}
